import React  from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import './assets/font/Nunito-Bold.ttf';
import './assets/font/Nunito-Medium.ttf';
import './assets/font/Nunito-Regular.ttf';
import './assets/font/Nunito-Light.ttf';
import './assets/font/Roboto-Light.ttf'


import reportWebVitals from './reportWebVitals';
///import * as serviceWorker from './serviceWorker';

if ('serviceWorker' in navigator) {
  // , { scope: '/' }
  window.addEventListener('load', function () {
      navigator.serviceWorker
          .register("sw.js", { updateViaCache:'none', scope: '/' })
          .then(function (res) {
              console.log('service worker registered .',res);

          })
          .catch(function (err) {
              console.log('SW Errors .', err);

          });
  });
}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  
);

///serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
