import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GridLayout from "react-grid-layout";
import Select from "react-select";

import { VisibilityContext } from "../contexts/visibilityContext";

import styles from "../styles/project.module.scss";

const Project = () => {
  const [submitStatus, setSubmitStatus] = useState("Submit");
  const { projectsFrmShow } = useContext(VisibilityContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    isSubmiting,
    control,
  } = useForm({
    mode: "onTouch",
    reValidateMode: "onChange",
  });

  toast.configure();

  // const handleFrmClose = () => {
  //   history.push("/");
  //   setFormsHide();
  // }

  document.title = "SofTesting | Tasks";

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [sendEmail, setSendEmail] = useState(null);
  const [projectInfo, setProjectInfo] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [email, setEmail] = useState(null);


  const location = window.location.hostname;
  let getUrl = "";

  // if (location === "localhost") {
  //   getUrl = `http://${location}:8000/api/task/getProjects`;
  // } else {
  getUrl = `https://api.softestingca.com/api/task/getProjects`;
  // }

  useEffect(() => {
    let emailW = "";
    setEmail(JSON.parse(localStorage.getItem("userEmail")));
    //console.log(JSON.parse(localStorage.getItem("userEmail")));
    if (JSON.parse(localStorage.getItem("userEmail"))) {
      emailW = JSON.parse(localStorage.getItem("userEmail"));
    }

    axios.get(getUrl, { params: { email: emailW } }).then((res) => {
      let projectList0 = [];
      for (let i = 0; i < res.data.length; i++) {
        const projectItem = {
          value: (projectList[(i, 0)] = res.data[i].projectId),
          label: (projectList[(i, 1)] = res.data[i].projectName),
        };
        projectList0.push(projectItem);
      }
      setProjectList(projectList0);
      console.log("res.data.length", res.data.length);
      console.log("projectList", projectList);
    });
  }, []);

  const onSubmit = (data, e) => {
    setSubmitStatus("Sending");

    let url = "",  
        url_saveMember = "",
        urlRole = "";
    console.log("", data);
    
    url = `https://api.softestingca.com/api/task/saveProject`;
    urlRole = `https://api.softestingca.com/api/user/userRole`;
    url_saveMember = `https://api.softestingca.com/api/project/saveProjectMember`;
    
   

    if (email) {
      axios.get(urlRole, { params: { email } }).then((res) => {
        setUserRole(res.data);
        console.log("userRole", res.data);
       
        if (
          res.data !== "admin" ||
          res.data === "" ||
          JSON.parse(localStorage.getItem("userEmail")) === null ||
          JSON.parse(localStorage.getItem("userEmail")) === ""
        ) {
          toast.error("Access Denied", { theme: "dark" });
          return;
        }
      });
    }
 
    projectName? setValue("projectName", projectName.label): null;
   console.log('data0009', data)
    if (userRole === 'admin') {
    if (data.email === "" || (data.projectName === "" && projectName.label =="") || data.memberName === "") {
      toast.error("Please enter data", { theme: "dark" });
    } else {
      axios({
        method: "post",
        url: url,
        data: data,
      })
        .then(({ data, status }) => {
          if (status === 455) {
            toast.success("Access Denied", { theme: "dark" });
            return;
          }
          if (status === 200) {
            toast.success("Project insert Is Done.", { theme: "colored" });
          } else if (status === 202) {
            toast.success("Project update Is Done.", { theme: "colored" });
          } else {
            toast.success("failed to save project.", { theme: "dark" });
            return;
          }
        })
        .catch((ex) => {
          console.log('ex.error', ex.error)
          toast.error("failed to save.", { theme: "dark" });
        });

        axios({
            method: "post",
            url: url_saveMember,
            data:data,  ///, projectId: projectName? projectName.value : 0},
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success("project member is inserted.", {
                  theme: "colored",
                });
              } else {
                toast.error("projectName is not found.", {
                  theme: "dark",
                });
              }
            })
            .catch((error) => {
              toast.error("project member failed to inserted.", {
                theme: "dark",
              });
            });

     
      setSubmitStatus("Done");
    }
    }
  };

 
 

  const handleChangeSendEmail = (e) => {
    setSendEmail(e.target.value);
  };

  const handleItem = (e) => {
    setValue("projectName", e.label);
    setValue("projectId", e.value);
    setProjectName(e);
  };

  const layout = [{ i: "a", x: 0, y: 0, w: 1, h: 2, static: true }];
  console.log('errors', errors)
  return (
    <form
      className={projectsFrmShow ? styles.container : styles.inactive}
      onSubmit={handleSubmit(onSubmit)}
    >
      <button
        type="submit"
        id={styles.submit}
        tabIndex="0"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmiting}
      >
        {submitStatus}
      </button>

      <Select
        {...register("projectId")}
        id={styles.selectProject}
        options={projectList}
        isSearchable
        placeholder="Select or type..."
        tabIndex="0"
        onChange={e => handleItem(e)}
        // onChange={setProjectName}
      />

      <input
        type={styles.text}
        id={styles.projectName}
        autoFocus
        placeholder="Project Name"
        className={`${errors.projectName ? styles.errorBorder : styles.Border}`}
        defaultValue={projectName ? projectName.label : ""}
        name="projectName"
        tabIndex="0"
        {...register("projectName", {
         // required: "*",
          minLength: (4, "*"),
          maxLength: (150, "*"),
        })}
      />

     <input
        id={styles.email}
        placeholder="Owner Email Address"
        className={`${errors.email ? styles.errorBorder : styles.Border}`}
        defaultValue=""
        type="email"
        name="email"
        tabIndex="0"
        {...register("email", {
          required: "*",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "*",
          },
        })}
      />

      <input
        type={styles.text}
        id={styles.ownerName}
        placeholder="Owner Name"
        className={`${errors.ownerName ? styles.errorBorder : styles.Border}`}
        defaultValue=""
        name="ownerName"
        tabIndex="0"
        {...register("ownerName", {
          required: "*",
          minLength: (4, "*"),
          maxLength: (150, "*"),
        })}
      />




      <input
        id={styles.memberEmail}
        placeholder="Team Member Email Address"
        className={`${errors.memberEmail ? styles.errorBorder : styles.Border}`}
        defaultValue=""
        type="email"
        name="memberEmail"
        tabIndex="0"
        {...register("memberEmail", {
          required: "*",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "*",
          },
        })}
      />

      <input
        type={styles.text}
        id={styles.memberName}
        placeholder="Team Member Name"
        className={`${errors.memberName ? styles.errorBorder : styles.Border}`}
        defaultValue=""
        name="memberName"
        tabIndex="0"
        {...register("memberName", {
          required: "*",
          minLength: (4, "*"),
          maxLength: (150, "*"),
        })}
      />


      <input
        type={styles.text}
        id={styles.memberRole}
        placeholder="Team Member Role"
        className={`${errors.memberRole ? styles.errorBorder : styles.Border}`}
        defaultValue=""
        name="memberRole"
        tabIndex="0"
        {...register("memberRole", {
          required: "*",
          minLength: (4, "*"),
          maxLength: (500, "*"),
        })}
      />

      <input
        id={styles.sendEmail}
        name="sendEmail"
        onChange={handleChangeSendEmail}
        type="checkbox"
        {...register("sendEmail")}
      />
 
      <span id={styles.sendEmailLabel}>Send Email</span>

      {errors.projectName && (
        <p id={styles.pError} role="alert">
          {errors.projectName.message}
        </p>
      )}
       {errors.ownerName && (
        <p id={styles.oError} role="alert">
          {errors.ownerName.message}
        </p>
      )}
      {errors.memberName && (
        <p id={styles.mNError} role="alert">
          {errors.memberName.message}
        </p>
      )}
      {errors.email && (
        <p id={styles.eError} role="alert">
          {errors.email.message}
        </p>
      )}
      {errors.memberEmail && (
        <p id={styles.meError} role="alert">
          {errors.memberEmail.message}
        </p>
      )}
       {errors.memberRole && (
        <p id={styles.rError} role="alert">
          {errors.memberRole.message}
        </p>
      )}
       
    </form>
  );
};
export default Project;
